import {gsap, ScrollTrigger} from "../../shared/scrollConfig";

gsap.registerPlugin(ScrollTrigger);

export default function slider() {


    const s = document.querySelectorAll('.slider3D');

    if (s.length) s.forEach(e => slider3D(e));

    function slider3D(s) {

        let stream = s.querySelectorAll('.carousel__slider');
        if (!stream.length) return;
        let circle = s.querySelector('.circle-gradient .c');
        let navBtn = s.querySelectorAll('.nav-slider');
        let length = 841.6066284179688;
        let items = s.querySelectorAll('.carousel__slider__item');
        let itemsLength = items.length;
        let itemsLengthChange = itemsLength;
        let count = itemsLength - 1;


        (itemsLength < 5) ? stream.forEach(row => changeWidth(row)) : moveSlider(0);

        function changeWidth(row) {
            if (itemsLengthChange < 5) {
                items.forEach(i => row.appendChild(i.cloneNode(true)));
                itemsLengthChange = row.querySelectorAll('.carousel__slider__item').length;
                changeWidth(row)
            } else moveSlider(0);
        }

        hideStroke(count);

        navBtn.forEach(function (b) {

            b.addEventListener('click', e => {
                let c = b.getAttribute('data-nav');
                b.classList.add('disable');

                moveSlider(c);

                if (+c) {
                    (count > 0) ? count += -1 : count = itemsLength - 1;
                    hideStroke(count);
                } else {
                    count += 1;
                    if (count >= itemsLength) count = 0;
                    hideStroke(count);
                }

                setTimeout(e => {
                    b.classList.remove('disable')
                }, 1000)
            });
        });

        function moveSlider(n) {

            stream.forEach(str => {
                let items = str.querySelectorAll('.carousel__slider__item');

                (+n) ? str.appendChild(items[0]) : str.insertBefore(items[items.length - 1], items[0]);
                // items = str.querySelectorAll('.carousel__slider__item');
            })


        }

        function hideStroke(c) {
            let dashoffset = length / itemsLength * c;
            // console.log(dashoffset);
            circle.setAttribute("style", "stroke-dasharray: " + length + "; stroke-dashoffset: " + dashoffset + ";");
        }

    }

}