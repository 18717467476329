import { togglePopUp } from "../../shared/pop-up";
import { Scrollbar } from "../../shared/scrollConfig";

export default function staffInfoPopUp() {
  let staffSlider = document.getElementById("staff3Dslider");

  if (!staffSlider) return;

  let personBtn = staffSlider.querySelectorAll(".swiper-slide");
  let popUp = document.getElementById("pop-up");
  const resultsWrapper = popUp.querySelector(".content .scroll-desc");
  const resultScroll = Scrollbar.init(resultsWrapper);

  personBtn.forEach((b) => openPopUpInfo(b, popUp));

  document.addEventListener("keydown", (e) => {
    if (e.code === "Escape") popUp.classList.remove("active");
  });
}

function openPopUpInfo(b, popUp) {
  let popupPhoto = popUp.querySelector("img"),
    popupName = popUp.querySelector(".name"),
    popupPos = popUp.querySelector(".position"),
    popupDesc = popUp.querySelector(".desc"),
    contact = popUp.querySelector(".contact"),
    popupTell = contact ? contact.querySelector(".data-tell") : null,
    popupEmail = contact ? contact.querySelector(".data-mail") : null,
    popupIn = contact ? contact.querySelector(".data-in") : null,
    popupTw = contact ? contact.querySelector(".data-tw") : null;

  b.addEventListener("click", (e) => {
    let person = b.closest(".person");
    let dataSrcset;
    if (person) dataSrcset = person.getAttribute("data-image-srcset");

    dataSrcset ? addSrcset(person, dataSrcset) : changeInfo(person, b);
    setTimeout((e) => togglePopUp(popUp), 200);
  });

  function changeInfo(person, b) {
    let tw_href = b.getAttribute("data-tw");
    let in_href = b.getAttribute("data-in");
    let tell_href = b.getAttribute("data-tell");
    let email_href = b.getAttribute("data-email");
    popupPhoto.src = b.querySelector("img").src;
    popupName.innerHTML = b.querySelector(".person-name").textContent;
    popupPos.innerHTML = b.querySelector(".position").textContent;
    popupDesc.innerHTML = b.querySelector(".desc").textContent;

    contact.classList.toggle(
      "hidden",
      !(tw_href || in_href || tell_href || email_href)
    );

    tw_href
      ? changeHref(popupTw, tw_href, false)
      : (popupTw.style.display = "none");
    in_href
      ? changeHref(popupIn, in_href, false)
      : (popupIn.style.display = "none");
    tell_href
      ? changeHref(popupTell, tell_href, "tel")
      : (popupTell.style.display = "none");
    email_href
      ? changeHref(popupEmail, email_href, "mailto")
      : (popupEmail.style.display = "none");
  }

  function changeHref(c, href, val) {
    c.style.display = "block";
    if (val) {
      c.setAttribute("href", `${val}:${href}`);
      // c.innerHTML = href;
      return;
    }
    c.href = href;
  }

  function addSrcset(person, data) {
    popupPhoto.src = person.querySelector("img").src;
    popupPhoto.setAttribute("srcset", data);
    popUp.classList.add("voice");
  }
}

export { openPopUpInfo };
