import { gsap, ScrollTrigger, Scrollbar } from "../../shared/scrollConfig";
gsap.registerPlugin(ScrollTrigger);

// Search members on 3D globe
export default function globeSearch() {

  const container = document.getElementById('globe');
  if (!container) return;

  const world = Globe({ antialias: false, alpha: false })(container);
  const duration = 1500;
  const alt = 1.75;
  const activeColor = 'rgba(83, 51, 237, 1)';
  const passiveColor = '#B3B3B3';
  const companies = JSON.parse(container.dataset.members);
  const globalCompanies = companies.filter(company => company.global);
  const searchInput = document.getElementById('searchInput');
  const results = document.querySelector('.members-search .results');
  const searchLabel = document.querySelector('.members-search .search__label');
  const cleanInput = document.getElementById('cleanInput');
  const infoPopup = document.querySelector('.globe__info-popup');
  const popupTitle = infoPopup.querySelector('.title');
  const popupCountry = infoPopup.querySelector('.country');
  const popupAddress = infoPopup.querySelector('.address');
  const popupPname = infoPopup.querySelector('.person-name');
  const popupUrl = infoPopup.querySelector('.web-url');
  const popupPemail = infoPopup.querySelector('.person-email');
  const closePopup = infoPopup.querySelector('.close');

  const resultsWrapper = document.querySelector('.results-all');
  const resultScroll = Scrollbar.init(resultsWrapper);

  // Canvas size
  const sizes = {
    width: container.clientWidth,
    height: container.clientHeight
  };

  let animated = true;
  let updateCompanies = true;

  globeInit();
  renderCountries();
  renderGlobalCompanies();


  // ScrollTrigger.create({
  //   trigger: container,
  //   once: true,
  //   onEnter() {
  //     console.log('Enter');
  //     globeInit();
  //     renderCountries();
  //     renderGlobalCompanies();
  //   }
  // });

  // Render globe
  function globeInit() {

    world
      .width(sizes.width)
      .height(sizes.height)
      .showGlobe(false)
      .showAtmosphere(false)
      .pointOfView({ altitude: alt }, duration * 2)
      .backgroundColor('rgba(255,255,255,0)');

    // Disable orbit zoom
    world.controls().enableZoom = false;

    // Auto-rotate
    // world.controls().autoRotate = true;
    // world.controls().autoRotateSpeed = 0.2;


  }

  // Render countries
  function renderCountries() {

    fetch(`${BAMBOO.assets}img/data/countries.json`).then(res => res.json()).then(countries => {
      world
        .hexPolygonsData(countries.features)
        .hexPolygonResolution(3)
        .hexPolygonMargin(0.5)
        .hexPolygonsTransitionDuration(duration)
        .hexPolygonColor(d => passiveColor);
    });
  }

  // Global companies
  function renderGlobalCompanies() {

    // Add random global companies data
    randomizeGlobalCompanies();

    world
      .customLayerData(globalCompanies)
      .customThreeObject(d => {
        if (d.logo) {
          return new THREE.Mesh(
            new THREE.CircleGeometry(d.radius, 30),
            new THREE.MeshBasicMaterial({ map: new THREE.TextureLoader().load(d.logo) })
          )
        }
      })
      .customThreeObjectUpdate((obj, d) => {
        Object.assign(obj.position, world.getCoords(d.lat, d.lng, d.alt));
        obj.lookAt(world.camera().position);
      })
      .onCustomLayerHover(logo => {
        container.style.cursor = logo ? 'pointer' : 'auto';
      })
      .onCustomLayerClick(clicked => {
        showCompanyInfo(clicked.id);
      });

    animateGlobalCompanies();
  }

  // Update global companies location
  function randomizeGlobalCompanies() {
    globalCompanies.forEach(company => {
      company.lat = (Math.random() - 0.5) * 180;
      company.lng = (Math.random() - 0.5) * 360;
      company.alt = Math.random() * 0.05 + 0.05;
      company.radius = 7;
    });
  }

  // Rotate global companies randomly
  function animateGlobalCompanies() {
    if (animated) {
      globalCompanies.forEach(d => d.lat += 0.05);
      world.customLayerData(globalCompanies);
      requestAnimationFrame(animateGlobalCompanies);
    }
  }

  // Rotate globe to coordinates
  function rotateToPoint(lat, lng) {
    world.pointOfView({ lat: lat, lng: lng, altitude: alt }, duration);
  }

  // Highlight selected country
  function showActiveCountry(iso) {

    // Colorize active country
    world.hexPolygonColor(({ properties: d }) => (d.ISO_A2 == iso || d.WB_A2 == iso) ? activeColor : passiveColor);

    // Rotate to country center
    world.hexPolygonsData().forEach(country => {

      if (country.properties.ISO_A2 == iso || country.properties.WB_A2 == iso) {

        // Get aproximate center coordinates
        let bbox = country.bbox;
        let lng = (bbox[0] + bbox[2]) / 2;
        let lat = (bbox[1] + bbox[3]) / 2;
        rotateToPoint(lat, lng);
      }
    });
  }

  // Animate globe (required if controls.autoRotate are set to true)
  // function animate() {
  //   world.controls().update();
  //   world.renderer().render(world.scene(), world.camera());
  //   requestAnimationFrame(animate);
  // }

  // Update renderer on window resize
  window.addEventListener('resize', () => {
    sizes.width = container.clientWidth;
    sizes.height = container.clientHeight;

    world.camera().aspect = sizes.width / sizes.height;
    world.camera().updateProjectionMatrix();

    world.renderer().setSize(sizes.width, sizes.height);
    world.renderer().setPixelRatio(Math.min(window.devicePixelRatio, 2));
  });

  cleanInput.addEventListener('click', e => {
    searchInput.value = '';
    searchLabel.classList.remove('active');
    innerAllCompanyItem()
  });

  // Search company / country
  searchInput.addEventListener('keyup', e => {

    const val = searchInput.value.toLowerCase();

    if (val.length < 3) {
      if (updateCompanies) innerAllCompanyItem();
      searchLabel.classList.remove('active');
    } else {
      searchLabel.classList.add('active');
      updateCompanies = true;
      const filteredCompanies = companies.filter(comp => comp.title.toLowerCase().includes(val) || comp.country_label.toLowerCase().includes(val));
      updateResults(filteredCompanies);
      inputSearch();
      hideCompanyInfo();
    }
  });

  // Create list item
  function createCompanyItem(item) {
    const a = document.createElement('a');
    const div = document.createElement('div');
    const title = document.createElement('span');
    const titleGrad = document.createElement('span');
    const desc = document.createElement('span');
    const descGrad = document.createElement('span');
    a.classList.add('res');
    a.href = '#';
    div.classList.add('info');
    title.classList.add('title');
    titleGrad.classList.add('title', 'gradient');
    desc.classList.add('desc');
    descGrad.classList.add('desc', 'gradient');
    a.appendChild(div);
    div.appendChild(title);
    div.appendChild(titleGrad);
    div.appendChild(desc);
    div.appendChild(descGrad);

    a.dataset.countryIso = item.country_iso;
    a.dataset.id = item.id;
    title.innerHTML = item.title;
    titleGrad.innerHTML = item.title;
    desc.innerHTML = item.country_label;
    descGrad.innerHTML = item.country_label;

    return a;
  }

  // Show results
  function updateResults(array) {
    results.innerHTML = '';
    if (array) {
      array.forEach(el => results.appendChild(createCompanyItem(el)));
    }
    // console.log(resultsWrapper);
    resultScroll.update();
    resultScroll.scrollTop = 0;
  }

  // Goto found company on click in list
  function inputSearch() {

    // Get found links
    const found = document.querySelectorAll('.members-search .results a');

    // Goto point on click
    [...found].forEach(link => link.addEventListener('click', e => {
      e.preventDefault();
      showCompanyInfo(link.dataset.id);
    }));
  }

  // Show popup with company info and pause animation
  function showCompanyInfo(companyID) {

    hideCompanyInfo();

    // Get company data
    const company = companies.filter(company => company.id == companyID)[0];

    // Toggle global companies / show active country
    globalCompanies.forEach(globalC => {
      if (globalC.__threeObj) {
        globalC.__threeObj.visible = (globalC.id == companyID) ? true : false
      }
    });

    if (company.global) {
      world.hexPolygonColor(d => activeColor);
      if (company.__threeObj) {
        company.__threeObj.lookAt(world.camera().position);
      }
      company.lat = world.pointOfView().lat;
      company.lng = world.pointOfView().lng;
    } else {
      showActiveCountry(company.country_iso);
    }
    console.log(company);

    // Add info to popup
    popupTitle.textContent = company.title;
    popupCountry.textContent = company.country_label;
    popupPemail.textContent = company.contact_person_email;
    popupPemail.href = `mailto:${company.contact_person_email}`;

    company.address ? popupAddress.classList.remove('none') : popupAddress.classList.add('none');
    popupAddress.innerHTML = company.address;

    company.contact_person_name ? popupPname.classList.remove('none') : popupPname.classList.add('none');
    popupPname.textContent = company.contact_person_name;

    company.url ? popupUrl.classList.remove('none') : popupUrl.classList.add('none');
    popupUrl.href = company.url;


    // Pause animations & show popup
    setTimeout(() => {
      animated = false;
      world.pauseAnimation();
      world.enablePointerInteraction(false);
      container.style.cursor = 'auto';
      container.style.pointerEvents = 'none';
      infoPopup.classList.add('visible');
    }, duration);
  }

  // Hide popup and resume animation
  function hideCompanyInfo() {

    if (animated) return;

    // Reset countries color
    world.hexPolygonColor(d => passiveColor);

    // Hide popup
    infoPopup.classList.remove('visible');

    // Show all global companies
    randomizeGlobalCompanies();

    globalCompanies.forEach(globalC => {
      if (globalC.__threeObj) {
        globalC.__threeObj.visible = true;
      }
    });

    // Resume animation
    animated = true;
    world.resumeAnimation();
    world.enablePointerInteraction(true);
    container.style.pointerEvents = 'auto';

    // rotateToPoint(0,0);
    animateGlobalCompanies();
  }

  // On popup close
  closePopup.addEventListener('click', hideCompanyInfo);

  function innerAllCompanyItem() {
    results.innerHTML = '';
    companies.forEach(el => results.appendChild(createCompanyItem(el)));
    inputSearch();
    hideCompanyInfo();
    updateCompanies = false;

  }
  innerAllCompanyItem();
}