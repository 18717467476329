import { gsap, ScrollTrigger, bodyScrollBar } from "../../shared/scrollConfig";

const header = document.querySelector('header');
const homeCircle = document.getElementById('homeCircle');
const preLoader = document.getElementById('preLoader');
const preloadLogo = preLoader.querySelector('.icmp-preloader__logo');

let musicDance = null;

window.addEventListener('load', () => {

    if (window.lottie && typeof(window.lottie) !== 'undefined') {
        musicDance = window.lottie.loadAnimation({
            container: homeCircle,
            renderer: 'svg',
            loop: true,
            autoplay: false,
            path: `${BAMBOO.assets}img/lottie/data.json`
        });
    }
});

export default function animations() {

    let animate = gsap.timeline({ paused: true });
    let animatePreload = gsap.timeline({
        delay: 0.5, paused: true, onComplete: () => {
            preLoadAnimation();
            scrollAnimations();
            // animatePreload.kill();
            // animatePreload = null;
        }
    });

    // Site preloader
    function preLoad() {
        animatePreload
            .to([preloadLogo, preLoader], { duration: 0.5, autoAlpha: 0, stagger: 0.3 });

        window.addEventListener('load', () => {
            animatePreload.play();
        });
    }

    // Page preload
    function preLoadAnimation() {


        // Common preloader
        animate.to(header, { duration: 1, autoAlpha: 1, ease: "none" }, "preload")

        // Homepage preloader
        if (homeCircle) {
            animate
                .call(() => {
                    // console.log(musicDance);
                    musicDance.play();
                }, null, "preload")
                .to(homeCircle, {
                    duration: 1.5,
                    autoAlpha: 1,
                    scale: 1,
                    filter: "blur(0px)",
                    ease: "elastic.out(1, 0.3)"
                }, "preload+=0.5")
        }

        animate.play();
    }

    // Scroll triggered animations
    function scrollAnimations() {

        const fadeIn = document.querySelectorAll('[anim-fadein]');
        const bg = document.querySelector('.background');
        const fadeInBlocks = document.querySelectorAll('[anim-fadein-block]');
        const inverted = document.querySelectorAll('.invert');
        const header = document.querySelector('header');

        // Fade in items in vewport
        if (fadeIn.length) {
            [...fadeIn].forEach(block => {

                let blockShow = gsap.timeline({
                    onComplete() {
                        ScrollTrigger.update();
                    },
                    scrollTrigger: {
                        trigger: block,
                        // toggleActions: "restart none none reverse"
                    }
                });

                blockShow
                    .to(block, { duration: 1, y: 0 }, "show")
                    .to(block, { duration: 2, autoAlpha: 1 }, "show");
            });
        }

        // Fade in items in viweport with delay
        if (fadeInBlocks.length) {

            [...fadeInBlocks].forEach(block => {

                let items = block.children;

                let blockShow = gsap.timeline({
                    onComplete() {
                        ScrollTrigger.update();
                    },
                    scrollTrigger: {
                        trigger: block,
                        // markers: true,
                        // start: "top center",
                        // toggleActions: "restart none none reverse"
                    }
                });

                blockShow
                    .to([items], {
                        duration: 1, y: 0, autoAlpha: 1, stagger: {
                            each: 0.2
                        }
                    }, "show")
            });
        }

        if (bg) {
            ScrollTrigger.create({
                trigger: 'section',
                start: "top 0.1%",
                toggleClass: { targets: bg, className: "visible" },
            });
        }

        // Header invert
        if (inverted.length) {
            let top = header.clientHeight / 2;

            inverted.forEach(invert => {
                ScrollTrigger.create({
                    trigger: invert,
                    start: "top " + top + "px",
                    end: "bottom " + top + "px",
                    toggleClass: { targets: header, className: "inverted" },
                });
            })
        }


    }

    preLoad();

}

export { musicDance };


