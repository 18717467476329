import Swiper, { Autoplay } from 'swiper';
Swiper.use([Autoplay]);

export default function membersSlider() {

    const members = document.getElementById('membersSlider');
    if (!members) return;

    const navCircle = members.closest('.slider-nav-wrapper').querySelector('.nav-button .circle-gradient .c');
    const navCircleLength = 841.6066284179688;
    const numOfSlides = members.querySelectorAll(".swiper-slide").length;

    const animateNavProgress = (swiper) => {

        let realIndex = swiper.realIndex + 1;
        let dashOffset = navCircleLength - (navCircleLength * (realIndex / numOfSlides));

        // if (realIndex < numOfSlides) {
            navCircle.setAttribute("style", "stroke-dasharray: " + navCircleLength + "; stroke-dashoffset: " + dashOffset + ";");
        // }
    }

    const memberSlider = new Swiper(members, {
        // initialSlide: 1,
        slidesPerView: 'auto',
        loop: true,
        loopedSlides: 4,
        grabCursor: true,
        // watchSlidesProgress: true,
        // watchSlidesVisibility: true,
        // freeMode: true,
        freeModeSticky: true,
        speed: 2000,
        autoplay: {
            delay: 1000
        },
        on: {
            'realIndexChange': swiper => {
                animateNavProgress(swiper);
            }
        }
    });
};


