export default function openPopUp() {

  let  popUp = document.getElementById('pop-up');

  if (popUp) {
    let closePopUp = popUp.querySelector('.close');
    document.querySelector('body').appendChild(popUp);

    closePopUp.addEventListener('click', e=>togglePopUp(popUp));
  }



}

function togglePopUp(p) {
  if(p.dataset.mobile) document.querySelector(p.dataset.mobile).classList.toggle('mob-overflow');
  p.classList.toggle('active');
  setTimeout( e=> p.classList.remove('success', 'error'), 500)
}

export { togglePopUp };


