// import { togglePopUp } from "pop-up";


export default function ccForm() {

  const forms = document.querySelectorAll('[data-ccform]');
  const popUp = document.getElementById('pop-up');


  if (forms.length) {
    [...forms].forEach(form => useForm(form));
  }

  // Form functions
  function useForm(form) {

    // togglePopUp(popup)


    const method = form.method;
    const inputs = [...form.elements].filter(input => input.type !== 'submit' && input.type !== 'hidden');
    const submit = [...form.elements].filter(input => input.type === 'submit')[0];
    const labels = inputs.map(input => input.closest('label')).filter(input => input);

    // Before request
    const beforeSend = () => {

      // Remove class 'error' from label
      labels.forEach(label => label.classList.remove('error'));
      // Lock submit button to prevent multiple clicks
      submit.disabled = true;
    };

    // After successful request
    const onSuccess = (message) => {

      // console.log(message);

      // Unlock submit button
      submit.disabled = false;

      // Reset form
      inputs.forEach(input => input.value = '');

      popUp.classList.add('active', 'success')
    };

    // Form validation error
    const onValidateError = (errors) => {

      submit.disabled = false;

      for (let key in errors) {

        let error = errors[key];
        // console.log(error);

        let input = inputs.filter(i => i.name === key)[0];
        let label = input.closest('label');

        label.classList.add('error');
      }

    };

    // Server error
    const onServerError = (error) => {
      console.log(error);

      popUp.classList.add('active', 'error')

    };

    // Send form via AJAX
    function sendForm() {

      // Before request send
      beforeSend();

      // Form data
      let data = new FormData(form);
      data.append('action', 'submit_ccform');

      // Send request
      fetch(BAMBOO.ajaxUrl, {
        method: method,
        credentials: 'same-origin',
        body: data
      })
        .then(response => response.json())
        .then(data => {

          console.log(data);
          if (data) {

            // On validate errors
            if (data['form_error']) {
              onValidateError(data['form_error']);
            }

            // On success
            if (data['mail_success']) {
              onSuccess(data['mail_success']);
            }

            // On mailsend fail
            if (data['mail_error']) {
              onServerError(data['mail_error']);
            }
          }
        })
        // Fetch error
        .catch(err => {
          onServerError(err);
        });
    }


    // Clean form & simple bot check
    inputs.forEach(input => input.addEventListener('change', () => {

      if (input.value) {
        let label = input.closest('label');
        !label || label.classList.remove('error');
      }

    }));

    // Submit form on click
    form.addEventListener('submit', e => {
      e.preventDefault();
      sendForm();
    });

  }

}