
export default function mapLegend() {

    const priorities = document.querySelector('.priorities-page');
    if (!priorities) return;
    const map = priorities.querySelector('.map');
    const legend = priorities.querySelector('.map-legend');
    const description = priorities.querySelector('.description');
    const subtitle = description.querySelector('.description-text');

    const buttons = legend.querySelectorAll('button');
    const panels = description.querySelectorAll('.panel');
    const points = map.querySelectorAll('.points');

    const title = description.querySelector('.title');

    [...buttons].forEach((button, idx) => button.addEventListener('mouseenter', () => {

        if (button.classList.contains('active')) return;


        // Toggle map points
        [...points].forEach((p, pIdx) => p.classList.toggle('active', pIdx === idx));

        // Toggle buttons
        [...buttons].forEach((b, bIdx) => b.classList.toggle('active', bIdx === idx));

        // Scroll to map
        // setTimeout(() => {
        //   bodyScrollBar.scrollIntoView(map);
        // }, 200);

        changeDescription(button, idx);

    }));


    function changeDescription(button, i) {
        const t = button.querySelector('.title');
        const color = button.dataset.color;

        toggleHide();
        setTimeout(e => {
            if (subtitle) subtitle.remove();
            title.textContent = t.textContent;
            title.style.color = color;

            // Toggle panels
            [...panels].forEach((p, pIdx) => p.classList.toggle('active', pIdx === i));

            toggleHide();
        }, 200)
    }

    function toggleHide() {
        description.classList.toggle('hide');
    }

    // changeDescription(buttons[0], 0);

}