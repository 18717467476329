import { bodyScrollBar } from "../../shared/scrollConfig";

export default function scrollDown() {

  let scroll = document.querySelector('.scrollDown');
  if (!scroll) return;

  let scrollTo = scroll.closest('section').nextElementSibling;

  let count = 200;
  let timerId;
  let timeout = 200;

  scroll.addEventListener('mouseenter', e => {

    timerId = setInterval(e => {
      (count >= 4000) ? scrollDown() : count += timeout;
    }, timeout);

  });

  scroll.addEventListener('mouseleave', stopInterval);

  function stopInterval() {
    clearInterval(timerId);
    count = 200;
  }

  function scrollDown() {
    stopInterval();
    scroll.click();
  }

  scroll.addEventListener('click', e => bodyScrollBar.scrollIntoView(scrollTo));
}