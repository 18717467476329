import Swiper from 'swiper';
import { gsap } from "gsap";
import mouseMoveParallax from "./mouseMoveParallax";


export default class newSlider {

    constructor(container, initial = 0, centered = false) {
        if (!container) return;

        this.init(container, initial, centered);
        this.setTouchAnimation(container);
    }

    setNavigation(swiper) {
        this.navCircle = this.nav.querySelector('.circle-gradient .c');
        this.navCircleLength = 841.6066284179688;
        // this.nextSlide = nav.querySelector('.nav__next');
        // this.prevSlide = nav.querySelector('.nav__prev');

        // this.nextSlide.addEventListener('click', () => {
        //     swiper.slideNext(1000, false);
        // });

        // this.prevSlide.addEventListener('click', () => {
        //     swiper.slidePrev(1000, false);
        // });
    }

    setTouchAnimation(container) {

        const slidesWrapper = container.querySelector('.swiper-wrapper');
        const sliderTitle = slidesWrapper.querySelector('.slider-title');
        const slides = slidesWrapper.querySelectorAll('.swiper-slide');

        this.touchAnimate = gsap.timeline({ paused: true });
        this.touchAnimate
            .to(slides, { duration: 0.2, scale: 0.9 }, "swipe")
            .to(sliderTitle, { duration: 0.5, autoAlpha: 1 }, "swipe");
    }

    setTranslate(swiper) {

        gsap.timeline()
            .to(swiper.slides, {
                duration: 2,
                x: swiper.translate,
            }, "slide");
    }

    animateNav(progress) {
        if (this.navCircleLength) {

            let dashOffset = this.navCircleLength - (this.navCircleLength * progress);
            this.navCircle.setAttribute("style", "stroke-dasharray: " + this.navCircleLength + "; stroke-dashoffset: " + dashOffset + ";");
        }
    }

    init(container, initial = 1, centered = false, loop = false) {

        const wrapper = container.closest('.slider-nav-wrapper');
        const parallax = new mouseMoveParallax(wrapper);

        this.nav = wrapper.querySelector('.nav-button');

        const that = this;

        this.swiper = new Swiper(container, {
            initialSlide: initial,
            centeredSlides: centered,
            slidesPerView: 'auto',
            grabCursor: true,
            watchSlidesProgress: true,
            watchSlidesVisibility: true,
            virtualTranslate: true,
            freeMode: true,
            // freeModeMomentumBounce: false,
            // loop: loop,
            // preventInteractionOnTransition: true,
            on: {
                init() {
                    that.setNavigation(this);
                    // parallax.play();
                },
                progress(swiper, progress) {
                    that.animateNav(progress);
                },
                setTranslate(translate) {
                    that.setTranslate(this, translate);
                },
                touchStart(swiper, e) {
                    if (!e.target.classList.contains('icon')) {
                        // parallax.pause();
                        that.touchAnimate.play();
                    }

                },
                touchMove(swiper, e) {
                    that.touchAnimate.play();
                },
                touchEnd(swiper, e) {
                    that.touchAnimate.timeScale(0.5).reverse();
                    // parallax.resume();
                }
            }
        });
    }
}
