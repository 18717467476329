export default function loadTwAPI() {

    function loadTw() {

        const tw = document.querySelector('.twitter__cont');

        if(tw) {
            let t = document.createElement("script");
            t.src = "https://platform.twitter.com/widgets.js";
            document.body.appendChild(t);
        }
    }
    window.addEventListener('load', loadTw, false);

}