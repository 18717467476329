import { musicDance } from "./animations";

export default function openMainMenu() {

  const body = document.querySelector('body');
  const openButton = document.querySelectorAll('.toggleOpenMain');

  openButton.forEach(btn => {
    btn.addEventListener('click', e => {

      if (body.classList.contains('openMainMenu')) {
        closeMenu();
      } else {
        openMenu();
      }
    })
  });

  document.addEventListener('keydown', e => {
    if (e.code === 'Escape' && body.classList.contains('openMainMenu')) {
      closeMenu();
    }
  });


  function openMenu() {
    if (musicDance) {
      musicDance.stop();
    }
    body.classList.add('openMainMenu');
  }


  function closeMenu() {
    if (musicDance) {
      musicDance.play();
    }
    body.classList.remove('openMainMenu');
  }






}





