import Scrollbar, { ScrollbarPlugin } from 'smooth-scrollbar';
import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);

// Set scrolling wrapper
const main = document.querySelector('main');

// Default Scrolltrigger options
ScrollTrigger.defaults({
  scroller: main,
});

// Scroll setup:
class ModalPlugin extends ScrollbarPlugin {
  static pluginName = 'modal';

  static defaultOptions = {
    open: false,
  };

  transformDelta(delta) {
    return this.options.open ? { x: 0, y: 0 } : delta;
  }
}

Scrollbar.use(ModalPlugin, /* OverscrollPlugin */);

const bodyScrollBar = Scrollbar.init(main);
bodyScrollBar.track.xAxis.element.remove();

const disableBodyScroll = () => bodyScrollBar.updatePluginOptions('modal', { open: true });
const enableBodyScroll = () => bodyScrollBar.updatePluginOptions('modal', { open: false });

// Tell ScrollTrigger to use these proxy getter/setter methods for the "body" element:
ScrollTrigger.scrollerProxy(main, {
  scrollTop(value) {
    if (arguments.length) {
      bodyScrollBar.scrollTop = value; // setter
    }
    return bodyScrollBar.scrollTop;    // getter
  },
  getBoundingClientRect() {
    return { top: 0, left: 0, width: window.innerWidth, height: window.innerHeight };
  }
});

// when the smooth scroller updates, tell ScrollTrigger to update() too:
bodyScrollBar.addListener(ScrollTrigger.update);

export { gsap, ScrollTrigger, Scrollbar, bodyScrollBar, enableBodyScroll, disableBodyScroll };