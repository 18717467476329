import { gsap } from "gsap";

export default class mouseMoveParallax {
  constructor(wrapper) {

    if (!wrapper) return;

    this.wrapper = wrapper;
    this.items = [...wrapper.querySelectorAll('[data-parallax]')];
    this.fixer = -0.05;
    this.centerX = window.innerWidth / 2;
    this.centerY = window.innerHeight / 2;
    this.paused = false;
    this.prevProgress;
  }

  _onMouseMove(e) {

    if (this.paused) return;

    let pageX = (e.pageX - this.centerX) * this.fixer;
    let pageY = (e.pageY - this.centerY) * this.fixer;

    this.items.forEach(item => {

      let speedX = item.getAttribute('data-x');
      let speedY = item.getAttribute('data-y');

      gsap.to(item, {
        x: (pageX * speedX),
        y: (pageY * speedY),
      });
    });
  }

  move() {
    if (!this.wrapper) return;
    this.wrapper.addEventListener('mousemove', e => {
      this._onMouseMove(e);
    })
  }

  pause() {
    this.paused = true;
  }

  resume() {
    setTimeout(() => {
      this.paused = false;
    }, 1500);
  }
}