import gsap from "gsap";
import { _debounce } from "../../shared/utilities";

export default function randomArtistsCircles() {
  const artists = document.getElementById("artists");

  if (!artists) return;

  try {
    const body = document.querySelector("body");
    const dragContainer = document.getElementById("moveContainer");
    const container = dragContainer.querySelector(".container");
    const circles = container.querySelectorAll(".c");
    const centerEl = container.querySelector(".center");
    const sqrt = Math.ceil(Math.sqrt(circles.length - 1));
    const widthEl = circles[1].clientWidth;
    const spaceBetween = widthEl * 0.8;

    let containerWidth = sqrt * (widthEl + spaceBetween);
    let containerHeight = containerWidth - spaceBetween * 2;

    containerHeight =
      containerHeight > container.clientHeight
        ? containerHeight
        : container.clientHeight;
    containerWidth =
      containerWidth > container.clientWidth
        ? containerWidth
        : container.clientWidth;

    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    container.style.width = containerWidth + "px";
    container.style.height = containerHeight + "px";

    const dragWidth = dragContainer.clientWidth;
    const dragHeight = dragContainer.clientHeight;

    dragContainer.style.left = -(dragWidth - window.innerWidth) / 2 + "px";
    dragContainer.style.top = -(dragHeight - window.innerHeight) / 2 + "px";

    let animationToCenter = gsap.timeline();

    const pinned = [];
    let isMoveTop, isMoveLeft;

    //Move the circles
    const movingCircles = (el) => {
      let icon = el.querySelector(".fly");

      let tl = gsap.fromTo(
        icon,
        { x: 0, y: 0 },
        {
          x: "random(-30%, 30%, 5)",
          y: "random(-30%, 30%, 3)",
          duration: 5,
          ease: "linear",
          repeat: -1,
          yoyo: true,
          repeatRefresh: true,
        }
      );

      const play = (e) => {
        // el.classList.remove("hovered");
        // circles.forEach((elem) => elem.classList.remove("disabled"));
        tl.play();
      };
      const pause = (e) => {
        // el.classList.add("hovered");
        tl.pause();
        // circles.forEach((elem) => elem.classList.toggle("disabled", elem !== el));

        icon.addEventListener("mouseleave", play);
      };

      // icon.addEventListener("click", (e) => {
      //   el.classList.remove("hovered");
      //   icon.removeEventListener("mouseleave", play);
      //   gsap.to(icon, { x: 0, y: 0, duration: 1 }).then((e) => tl.pause());
      //   timeline = tl;
      // });

      icon.addEventListener("mouseenter", pause);
    };

    //Find  random position within height/width
    const randomPoss = (range) => (Math.random() * range) | 0;

    // Check that the circles overlap
    const isOverlaps = (fr, sc) => {
      let rad = (fr.radius + sc.radius) * 1.5;
      let distance = Math.sqrt((sc.x - fr.x) ** 2 + (sc.y - fr.y) ** 2);
      return distance > rad;
    };

    // Find the closest point to the center
    const findPlace = (el, i) => {
      let j = 0;
      let p = {
        e: el,
        x: null,
        y: null,
        radius: el.clientWidth / 2.3,
        d: 1000000,
      };
      while (j < 1000) {
        let x = {
          x: randomPoss(containerWidth),
          y: randomPoss(containerHeight),
          radius: p.radius,
        };
        let d = Math.sqrt((pinned[0].x - x.x) ** 2 + (pinned[0].y - x.y) ** 2);

        if (d < p.d && pinned.every((placed) => isOverlaps(x, placed))) {
          p.x = x.x;
          p.y = x.y;
          p.d = d;
        }
        j++;
      }

      let pX = p.x / (containerWidth / 100);
      let pY = p.y / (containerHeight / 100);

      if (pX == 0 && pY == 0) {
        location.reload();
        return;
      }
      el.style.top = pY + "%";
      el.style.left = pX + "%";

      el.classList.add("placed");
      // el.addEventListener("click", (e) => moveOnClick(el, i));
      pinned.push(p);
    };

    //Drag the container
    function customDragContainer() {
      let pos = { x: 0, y: 0 };
      let mouse = { x: 0, y: 0 };
      let isMoved = false;
      let isMovedX = 0;
      let isMovedY = 0;
      let maxX = (dragWidth - windowWidth) / 2;
      let maxY = (dragHeight - windowHeight) / 2;
      let speed = 0.1;

      let pos1 = 0,
        pos2 = 0,
        pos3 = 0,
        pos4 = 0;

      isMoveTop = maxY > 0;
      isMoveLeft = maxX > 0;

      if (!isMoveTop && !isMoveLeft) return;

      let xSet = gsap.quickSetter(dragContainer, "x", "px");
      let ySet = gsap.quickSetter(dragContainer, "y", "px");

      function centered() {
        if (isMoved) return;

        centerEl.classList.remove("moved");
        body.classList.add("animate");

        gsap.ticker.remove(setCoords);

        animationToCenter.to(dragContainer, {
          duration: 1,
          x: 0,
          y: 0,
          onComplete: (e) => {
            pos = { x: 0, y: 0 };
            mouse = { x: 0, y: 0 };
            body.classList.remove("animate");
            isMovedX = 0;
            isMovedY = 0;
          },
        });

        // isMoveLeft && xSet(pos.x);
        // isMoveTop && ySet(pos.y);
      }

      const fixNum = (n) => n.toFixed(3);

      function setCoords() {
        let dt = 1.0 - Math.pow(1.0 - speed, 1);
        let preX = pos.x;
        let preY = pos.y;

        pos.x += (mouse.x - pos.x) * dt;
        pos.y += (mouse.y - pos.y) * dt;

        isMoveLeft && xSet(pos.x);
        isMoveTop && ySet(pos.y);

        // fixNum(preX) == fixNum(pos.x) &&
        // fixNum(preY) == fixNum(pos.y) &&
        // gsap.ticker.remove(setCoords);
      }

      function coof(mouse, max, pos) {
        if ((mouse < -max && pos > 0) || (mouse > max && pos < 0)) {
          return (Math.abs(mouse / max) - 1) * 100;
        } else {
          return 2;
        }
      }

      function onMouseMove(e) {
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;

        if (Math.abs(isMovedX) > 20 || Math.abs(isMovedY) > 20) {
          isMoved = true;
          container.classList.add("disabled");
          centerEl.classList.add("moved");
        } else {
          isMovedX += pos1;
          isMovedY += pos2;
        }

        mouse.x = mouse.x - pos1 / coof(mouse.x, maxX, pos1);
        mouse.y = mouse.y - pos2 / coof(mouse.y, maxY, pos2);
      }

      function onMousedown(e) {
        pos3 = e.clientX;
        pos4 = e.clientY;
        body.classList.add("grabbing");

        body.addEventListener("mousemove", onMouseMove);

        body.addEventListener("mouseup", onMouseUp);

        gsap.ticker.add(setCoords);
      }

      function onMouseUp() {
        isMoved = false;

        body.removeEventListener("mousemove", onMouseMove);
        body.removeEventListener("mouseup", onMouseUp);

        if ((mouse.x > maxX || mouse.x < -maxX) && isMoveLeft) {
          // isMoved = true;
          // let x = mouse.x > 0 ? maxX : -maxX;
          // mouse.x = x;
          // pos.x = x;
          // xSet(pos.x);
          // console.log("xSet");
          // transition();
        }
        if ((mouse.y > maxY || mouse.y < -maxY) && isMoveTop) {
          // isMoved = true;
          // let y = mouse.y > 0 ? maxY : -maxY;
          // mouse.y = y;
          // pos.y = y;
          // console.log("ySet");
          // ySet(pos.y);
          // transition();
        }

        body.classList.remove("grabbing");
        container.classList.remove("disabled");
      }

      body.addEventListener("mousedown", onMousedown);

      body.addEventListener("mouseleave", onMouseUp);

      centerEl.addEventListener("click", centered);
    }

    circles.forEach((el, i) => {
      if (i == 0) {
        pinned.push({
          e: el,
          x: containerWidth / 2,
          y: containerHeight / 2,
          radius: el.clientWidth / 3.3,
        });
        return;
      }
      findPlace(el, i);
      movingCircles(el, i--);
    });

    setTimeout(() => {
      let sorted = pinned;

      const disable = (el) => {
        sorted.sort(function (a, b) {
          return (
            Math.sqrt((el.x - a.x) ** 2 + (el.y - a.y) ** 2) -
            Math.sqrt((el.x - b.x) ** 2 + (el.y - b.y) ** 2)
          );
        });

        sorted.forEach((elm, i) => {
          setTimeout(() => {
            elm.e.classList.remove("opacity_0");
          }, 100 * i);
        });
      };

      disable(pinned[0]);
    }, 500);

    const onIconHover = () => {
      let sorted = pinned;
      let leaved = false;

      const disable = (el) => {
        leaved = false;

        sorted.sort(function (a, b) {
          return (
            Math.sqrt((el.x - a.x) ** 2 + (el.y - a.y) ** 2) -
            Math.sqrt((el.x - b.x) ** 2 + (el.y - b.y) ** 2)
          );
        });

        sorted.forEach((elm, i) => {
          setTimeout(() => {
            !leaved && elm.e.classList.toggle("disabled", elm.e !== el.e);
          }, 10 * i);
        });
      };

      const enable = () => {
        sorted.forEach((elm, i) => {
          leaved = true;
          elm.e.classList.remove("disabled");
        });
      };

      pinned.forEach((el) => {
        let icon = el.e.querySelector(".fly");
        icon && icon.addEventListener("mouseenter", (e) => disable(el));
        icon && icon.addEventListener("mouseleave", enable);
      });
    };

    customDragContainer();
    onIconHover();

    const onResize = () => location.reload();
    window.addEventListener("resize", _debounce(onResize), 1000);
  } catch (e) {
    location.reload();
  }
}
