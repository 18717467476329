import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);

import { openPopUpInfo } from "./staff-info";


export default function teamAnimation() {

    let persons = document.querySelectorAll('.teams .person');
    let  popUp = document.getElementById('pop-up');


    persons.forEach( person =>{
        let icon = person.querySelector('.icon');

        let tl = gsap.timeline ()
            .to(icon,{
                x: "random(-40, 40, 5)", //chooses a random number between -20 and 20 for each target, rounding to the closest 5!
                y: "random(-40, 40, 3)",
                duration: 7,
                ease:"none",
                repeat:-1,
                repeatRefresh:true // gets a new random x and y value on each repeat
            });

        icon.addEventListener("mouseenter", e=> tl.pause());
        icon.addEventListener("mouseleave", e=> tl.play());

        openPopUpInfo(icon.querySelector('.i'), popUp)

    });


}