import { ScrollTrigger } from "../../shared/scrollConfig";

export default function newsLoadMore() {

  const loadMore = document.getElementById('loadMoreNews');
  const container = document.getElementById('newsContainer');
  if (!loadMore) return;

  let paged = 1;

  loadMore.addEventListener('click', () => {

    paged++;

    // Prepare data to send
    const data = new FormData();
    data.append('action', 'loadmore_news');
    data.append('paged', paged);

    // Send request
    fetch(BAMBOO.ajaxUrl, {
      method: "POST",
      credentials: 'same-origin',
      body: data
    })
      .then((response) => response.text())
      .then((data) => {
        if (data) {

          // Append or change results data
          container.innerHTML += data;

          ScrollTrigger.refresh();

          if (container.dataset.maxPages == paged) {
            loadMore.classList.add('hidden');
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  });


}