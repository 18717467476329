import all from "../shared/utilities";

import slider from "./modules/slider";
import staffInfoPopUp from "./modules/staff-info";
import teamAnimation from "./modules/team";
import historyBack from "./modules/history-back";
import newSlider from "./modules/newSlider";
import membersSlider from "./modules/membersSlider";
import togglePopUp from "../shared/pop-up";
import openMainMenu from "./modules/open-main";
import globeSearch from "./modules/globeSearch";
import scrollDown from "./modules/scroll-down";
import mapLegend from "../shared/mapLegend";
import loadTwAPI from "../shared/twitter";
import ccForm from "../shared/ccForm";
import animations from "./modules/animations";
import sheetSearch from "./modules/sheetSearch";
import newsLoadMore from "./modules/newsLoadMore";
import map from "./modules/map";
import lineSlider from "./modules/lineLoopSlider";
import mouseMoveParallax from "./modules/mouseMoveParallax";
import new3Dslider from "./modules/new3Dslider";
import randomArtistsCircles from "./modules/artists-circle"   
import awardSlider from "./modules/award"

document.addEventListener('DOMContentLoaded', e => {

    animations();
    slider();
    staffInfoPopUp();
    teamAnimation();
    togglePopUp();
    historyBack();
    scrollDown();
    mapLegend();
    openMainMenu();
    ccForm();
    // invertHeader();
    sheetSearch();
    newsLoadMore();
    loadTwAPI();
    membersSlider();
    map();
    lineSlider();
    awardSlider();

    window.addEventListener('load', ()=> {
        globeSearch();
        randomArtistsCircles();
    });


    const resources = document.getElementById('resourcesSlider');
    const inMedia = document.getElementById('inMediaSlider');
    const news = document.getElementById('newsSlider');
    const events = document.getElementById('events3Dslider');

    const info = document.querySelector('.information .column');
    const award = document.querySelector(".award-page");

    const tmi = document.getElementById('new3Dslider');
    const staff = document.getElementById('staff3Dslider');

    if(resources) {
        const resSlider = new newSlider(resources, 1, true);
    }

    if(inMedia) {
        const inMediaSlider = new newSlider(inMedia, 0, false);
    }

    if(news) {
        const newsSlider = new newSlider(news, 1, true);
    }

    if(events) {
        const eventsSlider = new new3Dslider(events);
    }

    if(info) {
        const infoParallax = new mouseMoveParallax(info);
        infoParallax.move();
    }
    if(award) {
        const infoParallax = new mouseMoveParallax(award);
        infoParallax.move();
    }

    if(tmi){
        const tmiSlider = new new3Dslider(tmi);
    }

    if(staff){
        const staffSlider = new new3Dslider(staff);
    }



});

