import {gsap, ScrollTrigger} from "../../shared/scrollConfig";

gsap.registerPlugin(ScrollTrigger);

export default function lineSlider() {

    const l = document.querySelector('.lineSlider');

    if (l) setTimeout(sliderLineLoop, 100);

    function sliderLineLoop() {
        let sliderRow = document.querySelectorAll('.animation__row--content');

        sliderRow.forEach((row, index) => {
            let w = row.clientWidth;
            let n = w;
            let windowW = window.innerWidth;
            let item = row.querySelectorAll('.animation__row__item');

            changeWidth();

            function changeWidth() {
                if (w < windowW + n) {
                    item.forEach(i => row.appendChild(i.cloneNode(true)));
                    w = row.clientWidth;
                    changeWidth()
                } else {
                    if (!((index + 1) % 2)) n = -n;
                    infinitelyMove(row, n);

                }

            }

        });
    }

    function infinitelyMove(elem, width) {

        let scrollDirection = 1;
        let scrollPrev = 1;

        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: elem,
                start: "top bottom",
                toggleActions: "play pause resume pause",
                onUpdate: self => {
                    scrollDirection = self.direction;
                    if (scrollDirection === scrollPrev) return;
                    scrollPrev = scrollDirection;
                    gsap.to(tl, {
                        timeScale: scrollDirection,
                    });
                }
            },
        });
        tl.to(elem, {
            x: width,
            repeat: -1,
            duration: Math.abs(width * 0.017),
            ease: "none",
        }).totalProgress(0.5);

        let tl2 = gsap.to(elem.parentNode, {
            scrollTrigger: {
                trigger: elem.parentNode,
                start: "top bottom",
                toggleActions: "play pause restart pause",
                scrub: 1,
            },
            xPercent: Math.sign(width) * 15,
            duration: 4,
            ease: "none",
        });

    }

}