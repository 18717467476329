import { ScrollTrigger } from "../../shared/scrollConfig";

export default function sheetSearch() {

  const section = document.querySelector('.database');
  if (!section) return;

  const letters = section.querySelectorAll('.abc > a');
  const results = section.querySelector('.results');
  const loadMore = section.querySelector('.load-more');
  const searchInput = section.querySelector('.search input');
  // const searchButton = section.querySelector('.search .button');

  searchInput.value = '';

  let ajaxData;
  let currentLetter = '';
  let searchStr = '';
  let paged = 1;

  // Filter by first letter
  function filterByLetter() {
    [...letters].forEach(l => l.classList.toggle('active', l.dataset.letter === currentLetter));
    searchStr = '';
    paged = 1;
    fetchProviders();
  }

  // AJAX search
  function fetchProviders() {

    // Prepare data to send
    const data = new FormData();
    data.append('action', 'filter_provider');
    data.append('letter', currentLetter);
    data.append('str', searchStr);
    data.append('paged', paged);

    // Send request
    fetch(BAMBOO.ajaxUrl, {
      method: "POST",
      credentials: 'same-origin',
      body: data
    })
      .then((response) => response.text())
      .then((data) => {
        if (data) {

          // Append or change results data
          if (paged > 1) {
            results.innerHTML += data;
          } else {
            results.innerHTML = data;
          }

          // locoScroll.update();
          ScrollTrigger.refresh();
          toggleLoadMore();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  // Show/hide loadmore button if more posts are available/not
  function toggleLoadMore() {

    ajaxData = document.getElementById('ajaxData');

    if (ajaxData && loadMore) {
      loadMore.classList.toggle('hidden', ajaxData.dataset.maxPages == paged);
    } if(!ajaxData) {
      loadMore.classList.add('hidden');
    }
  }

  // On letter click
  [...letters].forEach(l => l.addEventListener('click', e => {
    e.preventDefault();
    currentLetter = l.dataset.letter;
    searchInput.value = '';
    filterByLetter();
  }));

  // On loadmore click
  if (loadMore) {
    loadMore.addEventListener('click', () => {
      paged++;
      fetchProviders();
    });
  }

  // On search
  searchInput.addEventListener('keyup', e => {
    searchStr = searchInput.value.trim();

    if (searchStr.length > 2) {
      currentLetter = '';
      paged = 1;
      [...letters].forEach(l => l.classList.remove('active'));
      fetchProviders();
    } else {
      filterByLetter();
    }
  });



}