import { gsap } from "../../shared/scrollConfig";

export default function map() {

    const priorities = document.querySelector('.priorities-page');
    if (!priorities) return;

    const legend = priorities.querySelector('.map-legend');
    let pH = priorities.clientHeight;
    let wH = window.innerHeight;

    if (pH < wH) return;

    legend.parentNode.classList.add('fixed');

    gsap.to(legend, {
        scrollTrigger: {
            trigger: priorities,
            start: "top 0.1%",
            // endTrigger: ".priorities-page",
            end: 'bottom 100%',
            // markers: true,
            pin: legend,
            pinSpacing: false,
            toggleActions: "restart pause reverse pause",
        },
    })


}