import {
  gsap,
  ScrollTrigger,
  bodyScrollBar,
  enableBodyScroll,
  disableBodyScroll,
} from "../../shared/scrollConfig";

import Draggable from "gsap/Draggable";
gsap.registerPlugin(Draggable);

export default function awardSlider() {
  const award = document.querySelector(".award-page");
  if (!award) return;

  const popup = document.querySelector(".pop-up-awards");
  const slider = document.querySelector(".slider_circle");
  const lines = slider.querySelectorAll(".line");
  const awards = award.querySelectorAll(".award-slide");
  const descScreen = award.querySelector(".stage-2");

  const readCont = award.querySelector(".ream_more");
  const readMore = readCont.querySelector(".descroption p");
  let arrForNext = [...awards].slice(0);
  let arrForPrev = [...awards].slice(0);
  let rotate360 = gsap.timeline({ paused: true });
  let scrollTarget = "footer";

  const deg = 15;
  const speed = 0.4;
  let delta = 0,
    timer,
    isNeedChange = 0,
    activeIndex = 0,
    prevActive = 0,
    // real_index = 0,
    prev_i = 0,
    rotateToRounding;

  window.addEventListener("load", () => {
    // setTimeout(() => {
      lines.forEach((line, i) => {
        if (i == 0) line.classList.add("active");
        // setTimeout(() => {
          line.classList.remove("blur");
        // }, 300 * i);
      });
    // }, 1000);
  });

  const real_index = () => {
    let i = [...slider.querySelectorAll(".line")].findIndex((el) =>
      el.classList.contains("active")
    );
    // console.log(i);
    return i;
  };

  const readMoreInfo = (e) => {
    readCont.classList.add("hidden");
    let c = awards[real_index()].querySelector(".data-content .short_description").innerHTML;
    setTimeout(() => {
      readMore.innerHTML = c;
      readCont.classList.remove("hidden");
    }, 400);
  };

  const rotateToDeg = (d) => {
    // console.log(d);
    rotateToRounding = gsap.timeline({ paused: true });
    rotateToRounding.to(slider, {
      duration: speed * 5,
      rotate: d,
      ease: "back.out(2)",
      onUpdate: (e) => {
        let dg = slider.style.transform;
        dg = dg.split("rotate")[1];
        if (!dg) return;
        dg = dg.split("deg")[0];
        if (!dg) return;
        dg = dg.split("(")[1];
        if (!dg) return;
        slider.setAttribute("data-rotate", (+dg).toFixed(5));
      },
      onComplete: (e) => {
        if (d >= 360) {
          gsap.to(slider, {
            duration: 0,
            rotate: d - 360,
          });
          slider.setAttribute("data-rotate", +slider.dataset.rotate - 360);
        }
        if (d <= -360) {
          gsap.to(slider, {
            duration: 0,
            rotate: d + 360,
          });
          slider.setAttribute("data-rotate", +slider.dataset.rotate + 360);
        }
      },
    });
    rotateToRounding.play();
  };

  const changeSlideInfo = (ic, id) => {
    id >= lines.length && (id = id - lines.length);
    id < 0 && (id = lines.length + id);
    let thisL = lines[id];
    let dt1 = thisL.dataset.realIndex || false;
    let dt2 = ic.dataset.realIndex;
    if (dt1 === dt2) return;

    let content = ic.querySelector(".data-content");
    
    let titl = content.querySelector(".title").innerHTML;
    let source = content.querySelector(".source").innerHTML;
    let year = content.querySelector(".year").innerHTML;


    thisL.setAttribute("data-real-index", dt2);
    thisL.querySelector("img").src = source;
    thisL.querySelector(".title h2").innerHTML = titl;
    thisL.querySelector(".year").innerHTML = year;
  };

  const changeSlide = (id) => {
    // real_index += id;
    // console.log("changeSlide", real_index, id);
    activeIndex = isNeedChange;
    activeIndex <= -1 && (activeIndex = lines.length + isNeedChange);
    activeIndex >= lines.length && (activeIndex = isNeedChange - lines.length);

    // id > 0 && nextSlide();
    // id < 0 && prevSlide();

    lines.forEach((line, i) =>
      line.classList.toggle("active", i == activeIndex)
    );
  };

  function nextSlide() {
    let img = arrForNext.shift();
    real_index++;
    arrForNext.push(img);
    arrForPrev.push(arrForPrev.shift());

    real_index == awards.length && (real_index = 0);

    changeSlideInfo(img, awards.length - 1 + activeIndex);
  }

  function prevSlide() {
    let img = arrForPrev.pop();
    real_index--;
    arrForPrev.unshift(img);
    arrForNext.unshift(arrForNext.pop());

    real_index == -1 && (real_index = awards.length - 1);
    changeSlideInfo(img, activeIndex - awards.length);
  }

  function onDragOver(e) {
    let r = slider.dataset.rotate;
    timer = null;
    prev_i !== real_index && readMoreInfo();
    prev_i = real_index;
    rotateToDeg((Math.round(r / deg) + 0.0001 * Math.sign(e.deltaY)) * deg);
  }

  function moveOnWheel(e, coff = 1, isDrag = false) {
    // console.log("coff", coff);
    let rotateDeg = +slider.dataset.rotate;
    delta = (1.8 / coff) * Math.sign(e.deltaY) * -1;
    let isLast = rotateDeg < (lines.length - 1) * -deg;
    let isFirst = rotateDeg > 0 && e.deltaY < 0;
    let r = rotateDeg + delta / (isLast && e.deltaY > 0 ? 10 : 1);
    let or = r;
    or = !isLast ? or.toFixed(2) : (lines.length - 1) * -deg;
    // or = r - Math.floor(Math.abs(r / 360) * 1.01) * Math.sign(r) * 360;

    rotateToRounding && rotateToRounding.kill();

    if (isFirst) {
      award.removeEventListener("wheel", moveOnWheel);

      enableBodyScroll();
      scrollTarget = "footer";

      setTimeout(() => {
        gsap.to(slider, {
          duration: 3,
          rotate: -15.001,
        });
      }, 0);

      // setTimeout(() => {
      bodyScrollBar.scrollTo(0, descScreen.clientHeight, 4000);
      // }, 500);
      clearTimeout(timer);

      return;
    }

    isNeedChange = Math.round(or / deg) * -1;
    // console.log(isNeedChange, prevActive);

    isNeedChange !== prevActive && !isLast && changeSlide(Math.sign(e.deltaY));

    slider.setAttribute("data-rotate", r);
    slider.setAttribute("data-origin", or);

    // console.log(r, lines.length * -deg, isLast);

    let ease = "none";
    // let ease = (prevActive = isNeedChange) ? 'back.inOut(1.7)' : 'expo.out';

    prevActive = isNeedChange;

    gsap.to(slider, {
      duration: speed,
      rotate: r,
      ease: ease,
    });

    if (isDrag) return;

    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      prev_i !== real_index() && readMoreInfo();
      prev_i = real_index();
      let d = isLast
        ? (lines.length - 1) * -deg
        : (Math.round(r / deg) + 0.0001 * Math.sign(e.deltaY)) * deg;
      // console.log("setTimeout", d);

      rotateToDeg(d);
    }, speed * 900);
  }

  function initPopUp() {
    const togglePopUp = document.querySelectorAll(".toggle_pop-up");
    const title = popup.querySelector(".title");
    const icon = popup.querySelector(".icon img");
    const description = popup.querySelector(".description p");

    function openPopUp() {
      let content = awards[real_index()].querySelector(".data-content");      

      let titl = content.querySelector(".title").innerHTML;
      let source = content.querySelector(".source").innerHTML;
      let longD = content.querySelector(".long_description").innerHTML;
      title.innerHTML = titl;
      icon.src = source;
      description.innerHTML = longD;
      popup.classList.add("active");
    }
    function closePopUp() {
      popup.classList.remove("active");

      setTimeout(() => {
        description.innerHTML = "";
      }, 500);
    }

    togglePopUp.forEach((button) => {
      button.addEventListener("click", (e) => {
        button.classList.contains("read") && openPopUp();
        button.classList.contains("close") && closePopUp();
      });
    });
  }

  function sectionScroll() {
    const footer = document.querySelector("footer");
    const welcomeScreen = award.querySelector(".stage-1");
    const sliderScreen = award.querySelector(".stage-3");
    const welcomeTitle = award.querySelector(".welcome-title");
    const description = award.querySelector(".description-title");
    const circleBg = award.querySelector(".transform_bg");
    const sliderWrapper = award.querySelector(".move-slider");
    const sliderBody = sliderWrapper.querySelector(".award-slider");
    const text = sliderBody.querySelectorAll(".year, .title");
    const navButton = sliderWrapper.querySelector(".screen_nav");
    const titleButton = welcomeTitle.querySelector(".arr_bottom");
    const footerOffset = footer.offsetTop;
    let delta = { deltaY: 1 };
    let pr = 0;
    let deltaCoff = (window.innerHeight / 960) * 10;
    let dragged;

    // dragged = Draggable.create(award, {
    //   // type: "rotation",
    //   type: "y",
    //   onDrag: (e) => {
    //     delta.deltaY = dragged[0].deltaY * -1;
    //     moveOnWheel(delta, 8, true);
    //   },
    //   onDragEnd: (e) => {
    //     onDragOver(delta);
    //     gsap.to(award, {
    //       duration: 0,
    //       y: 0,
    //     });
    //   },
    // });

    // dragged[0].disable();

    titleButton.addEventListener("click", () => {
      bodyScrollBar.scrollTo(0, descScreen.clientHeight, 3000);
    });
    navButton.addEventListener("click", () => {
      if (scrollTarget === "footer") {
        bodyScrollBar.scrollTo(0, footerOffset - window.innerHeight, 2000);
        scrollTarget = "";
        allowSlider();
        gsap.to(slider, {
          duration: 2,
          rotate: 0.001,
        });
      } else {
        disableSlider();
        bodyScrollBar.scrollTo(0, 0, 2000);
        scrollTarget = "footer";

        lines.forEach((line, i) => line.classList.toggle("active", i == 0));

        // setTimeout(() => {
        //   slider.setAttribute("data-rotate", 0.001);
        //   slider.setAttribute("data-origin", 0.001);
        // }, 1000);

        // real_index = 0;
        gsap.to(slider, {
          duration: 2,
          rotate: -15.001,
          onComplete() {
            let c = awards[real_index()].querySelector(".data-content .short_description").innerHTML;
            readMore.innerHTML = c;
          },
        });
      }
    });

    // Pin welcome screen
    ScrollTrigger.create({
      trigger: welcomeScreen,
      pin: true,
      endTrigger: footer,
      pinSpacing: false,
      onUpdate() {},
    });

    const tl1 = gsap.timeline({
      scrollTrigger: {
        trigger: descScreen,
        scrub: true,
        start: "top bottom",
        end: "bottom bottom",
      },
      onStart() {
        // console.log("tl1 onStart");
      },
      onComplete() {
        // console.log("tl1 onComplete");
      },
    });

    const tl2 = gsap.timeline({
      scrollTrigger: {
        trigger: sliderScreen,
        scrub: 1,
        start: ` 5% bottom`,
        end: "bottom bottom",
        // markers: true,
      },
      onStart() {
        // console.log("tl2 onStart");
        bodyScrollBar.scrollTo(0, footerOffset - window.innerHeight, 1500);
        scrollTarget = "";
        gsap.to(slider, {
          duration: 1.5,
          rotate: 0.001,
        });
        slider.setAttribute("data-rotate", 0.001);
        slider.setAttribute("data-origin", 0.001);
        setTimeout(allowSlider, 1500);
      },
      onComplete() {},
    });

    tl1
      .to(welcomeTitle, { autoAlpha: 0, y: "-100vh" }, "scroll1")
      .from(circleBg, { xPercent: -15 }, "scroll1")
      .from(navButton, { autoAlpha: 0 }, "scroll1")
      .from(description, { autoAlpha: 0, y: "50vh" }, "scroll1");

    tl2
      .to(description, { duration: 1, autoAlpha: 0 }, "scroll2")
      .to(sliderWrapper, { duration: 2, x: 0 }, "scroll2")
      .to(sliderBody, { duration: 2, scale: 1 }, "scroll2")
      .to(text, { duration: 2, autoAlpha: 1 }, "scroll2")
      .to(
        navButton,
        { duration: 1, xPercent: -100, scale: 0.6, rotate: "180deg" },
        "scroll2"
      );

    const onMouseDown = (e) => {
      pr = e.clientY;
      // award.addEventListener("mousemove", onMouseMove);
      // award.addEventListener("mouseleave", onMouseUp);

      award.classList.add("grabbing");
    };

    const onMouseUp = () => {
      // onDragOver(delta);
      // award.removeEventListener("mousemove", onMouseMove);
      // award.removeEventListener("mouseleave", onMouseUp);

      award.classList.remove("grabbing");
    };

    const onMouseMove = (e) => {
      let s = Math.sign(pr - e.clientY);
      s !== 0 && (delta.deltaY = s);
      pr = e.clientY;
      moveOnWheel(delta, deltaCoff, true);
    };

    function allowSlider() {
      disableBodyScroll();
      // dragged[0].enable();
      // award.classList.add("grab");
      award.addEventListener("wheel", moveOnWheel);
      // award.addEventListener("mousedown", onMouseDown);
      // award.addEventListener("mouseup", onMouseUp);
    }

    function disableSlider() {
      enableBodyScroll();
      // dragged[0].disable();
      // award.classList.remove("grab");
      award.removeEventListener("wheel", moveOnWheel);
      // award.removeEventListener("mousedown", onMouseDown);
      // award.removeEventListener("mouseleave", onMouseUp);

      // onMouseUp();
    }
  }

  slider.setAttribute("data-rotate", 0.001);
  slider.setAttribute("data-origin", 0.001);

  lines.forEach((line, idx) => gsap.set(line, { rotate: deg * idx }));

  awards.forEach((award, id) => {
    award.setAttribute("data-real-index", id);
    changeSlideInfo(award, id);
    // changeSlideInfo(award, lines.length - awards.length + id);
  });

  gsap.to(slider, {
    duration: 0,
    rotate: -15.001,
  });

  popup && initPopUp();
  sectionScroll();
}
