import Swiper from 'swiper';
import { gsap } from "gsap";

export default class new3Dslider {

    constructor(container) {
        if (!container) return;
        this.init(container);
    }

    setNavigation(swiper) {
        if(!this.nav) return;
        this.navCircle = this.nav.querySelector('.circle-gradient .c');
        this.navCircleLength = 841.6066284179688;

    }

    animateNav(progress) {
        if (this.navCircleLength) {

            let dashOffset = this.navCircleLength - (this.navCircleLength * progress);
            this.navCircle.setAttribute("style", "stroke-dasharray: " + this.navCircleLength + "; stroke-dashoffset: " + dashOffset + ";");
        }
    }

    setTranslate(swiper) {

        let slides = swiper.slides;
        let slidesSizesGrid = swiper.slidesSizesGrid;
        let swiperWidth = swiper.width;
        let transform = swiper.translate;
        let center = -transform + swiperWidth / 2;
        let rotate = 8.7;

        slides.forEach((slide, index) => {

            let image = slide.querySelector('.slide-image');
            let slideSize = slidesSizesGrid[index];
            let slideOffset = slide.swiperSlideOffset;

            let offsetMultiplier = 2*(center - slideOffset - slideSize / 2) / slideSize;
            // console.log(center, slideOffset, slideSize, slideSize, offsetMultiplier);
            
            let absOffsetMultiplier = Math.abs(offsetMultiplier);
            let rotateY = rotate * offsetMultiplier;
            // let rotateY = rotate * offsetMultiplier;

            // console.log(index, ' - ',  offsetMultiplier);

            // if (rotateY >= 90) { rotateY = 90; }
            // if (rotateY <= -90) { rotateY = -90; }

            // let scale = 0.85 * absOffsetMultiplier;
            let scale = 1 - (1 - 1.115) * absOffsetMultiplier;
            let translateX = offsetMultiplier;

            // console.log( translateX, offsetMultiplier, absOffsetMultiplier, rotateY, scale );

            gsap.timeline()
                .to(slide, {
                    duration: 0.25,
                    x: translateX,
                    // perspective: 500,
                }, "animate")
                .to(image, {
                    duration: 0.5,
                    rotateY: rotateY,
                    scale: scale,
                    rotateX: absOffsetMultiplier*3,
                    rotateZ: -offsetMultiplier*1.45,
                    x: -rotateY*2.5,
                    y: absOffsetMultiplier*10,
                }, "animate");
        })
    }

    init(container) {

        const wrapper = container.closest('.slider-nav-wrapper');
        this.nav = wrapper.querySelector('.nav-button');

        const that = this;

        this.swiper = new Swiper(container, {
            initialSlide: 1,
            centeredSlides: true,
            slidesPerView: 3,
            grabCursor: true,
            watchSlidesProgress: true,
            spaceBetween: 60,
            // watchSlidesVisibility: true,
            // virtualTranslate: true,
            touchRatio: 0.5,
            freeMode: true,
            freeModeSticky: true,
            freeModeMomentumRatio: 0.2,
            freeModeMomentumVelocityRatio: 0.5,
            // freeModeMomentum: false,
            // loop: true,
            // longSwipesRatio: 0.2,
            // freeModeMomentumBounce: false,
            on: {
                init() {
                    that.setNavigation(this);
                },
                progress(swiper, progress) {
                    that.animateNav(progress);
                },
                setTranslate(translate) {
                    that.setTranslate(this, translate);
                }
                // onSetTranslate() {
                //     that.animateNav.apply(this, )
                // }
            }
        });
    }
}
